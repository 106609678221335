.wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  background: var(--color-neutral-50);
  touch-action: pan-x pan-y;

  &.visibleOverflow {
    aspect-ratio: unset;
  }

  & button[data-test-id='image-carousel-prev-btn'],
  & button[data-test-id='image-carousel-next-btn'] {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    & button[data-test-id='image-carousel-prev-btn'],
    & button[data-test-id='image-carousel-next-btn'] {
      opacity: 1;
    }
  }
}

.reservedChip {
  top: 16px;
  left: 16px;
  z-index: 1;
  display: flex;
  position: absolute;
  border-radius: 4px;
  background-color: var(--color-mica-blue-700);
  padding: var(--spacing-2) var(--spacing-2);
  color: var(--color-championship-white);
  @media (--tablet) {
    top: 24px;
    left: 24px;
  }
}

.btn {
  top: var(--spacing-3);
  z-index: 1;
  display: flex;
  position: absolute;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-championship-white);

  & > div {
    height: var(--spacing-6);
  }

  @media (--tablet) {
    top: var(--spacing-6);
  }
}

.shareBtn {
  right: 56px;

  & svg {
    margin-top: -1px;
  }

  @media (--tablet) {
    right: 80px;
  }

  @media (--tablet-l) {
    display: none;
  }
}

.favBtn {
  right: var(--spacing-3);

  @media (--tablet) {
    right: var(--spacing-6);
  }
}

.images {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  cursor: pointer;
  overscroll-behavior-y: contain;
  touch-action: pan-y;

  &[data-reservedstatus='true'] {
    opacity: 0.7;
    pointer-events: none;
  }

  &:global(.dragging) {
    cursor: grab;
    scroll-behavior: auto;
  }
}

.image {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: contain;
  object-position: center;
}

.imageSlide {
  flex: 0 0 100%;
  position: relative;
  background-color: var(--color-neutral-50);

  & img {
    width: 100%;
  }
}

.visibleOverflow {
  & .images {
    padding: 0 100px;
  }

  & .imageSlide {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
  }
}

.progress {
  position: absolute;
  right: var(--spacing-3);
  bottom: var(--spacing-3);
  z-index: 1;
  display: inline-block;
  padding: var(--spacing-1) var(--spacing-2);
  background: var(--color-overlay);
  color: var(--color-championship-white);

  @media (--tablet) {
    right: var(--spacing-6);
    bottom: var(--spacing-6);
    padding: var(--spacing-2) var(--spacing-3);
  }

  @media (--tablet-l) {
    position: static;
    min-width: 64px;
    line-height: 22px;
    text-align: center;
  }

  @media (--desktop) {
    padding: var(--spacing-3) var(--spacing-2);
  }
}

.racBadge {
  position: absolute;
  bottom: var(--spacing-2);
  left: var(--spacing-2);
  z-index: 1;
  display: flex;

  & > div {
    border-radius: var(--spacing-1);
    line-height: 20px;
  }
}
